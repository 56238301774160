import Products from '@components/Search/Products/Products.jsx'
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions.jsx'
import SearchStatic from '../SearchStatic/SearchStatic.jsx'
import Highlight from '../Highlight/Highlight.jsx'

import staticData from '../SearchData/staticData.js'

const SearchFlyout = ({
    suggestions,
    handleSuggestionClick,
    pages,
    collections,
    products,
    query,
}) => {
    // console.log('products in SearchFlyout', products)
    return (
        <div className='search-flyout-outer w-full h-full'>
            <div className='search-flyout-inner'>
                <div className='flyout-sections-outer p-6 w-full'>
                    <div className='flyout-sections-inner flex flex-wrap xl:flex-nowrap gap-8 w-full'>
                        <div className='flyout-section flyout-section-outer products-section xl:w-3/4 mt-1 mb-4'>
                            <div className='flyout-section-inner'>
                                <h3 className='flyout-section-title text-base xl:text-lg mb-2 flex items-center'>
                                    Products
                                    <a
                                        href={`/search?q=${query}`}
                                        className='view-all text-sm font-normal text-flint hover:text-ash hover:underline cursor-pointer ml-auto'
                                    >
                                        View All »
                                    </a>
                                </h3>
                                {products && products.length > 0 ? (
                                    <Products
                                        products={products}
                                        query={query}
                                    />
                                ) : (
                                    <div className='no-results'>
                                        <p className='font-normal text-flint text-sm'>
                                            No results found
                                        </p>
                                        <SearchStatic
                                            staticData={staticData}
                                            handleSuggestionClick={
                                                handleSuggestionClick
                                            }
                                            noresults={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flyout-section-stack xl:w-1/4'>
                            <SearchSuggestions
                                query={query}
                                suggestions={suggestions}
                                handleSuggestionClick={handleSuggestionClick}
                            />
                            <div className='flyout-section flyout-section-outer collections-section mt-1 mb-4'>
                                <div className='flyout-section-inner'>
                                    <h3 className='flyout-section-title text-base xl:text-lg mb-2'>
                                        Collections
                                    </h3>
                                    {collections && collections.length > 0 ? (
                                        <ul className='collections-list nostyle flex flex-col gap-2 px-2'>
                                            {collections.map((collection) => {
                                                if (
                                                    collection.title.indexOf(
                                                        'hidden'
                                                    ) === -1 &&
                                                    collection.handle.indexOf(
                                                        'test'
                                                    ) === -1
                                                ) {
                                                    return (
                                                        <li
                                                            key={collection.id}
                                                            className='text-sm'
                                                        >
                                                            <a
                                                                className='font-normal text-flint hover:text-ash hover:underline cursor-pointer'
                                                                href={`/collections/${collection.handle}`}
                                                            >
                                                                <Highlight
                                                                    search={
                                                                        query
                                                                    }
                                                                >
                                                                    {
                                                                        collection.title
                                                                    }
                                                                </Highlight>
                                                            </a>
                                                        </li>
                                                    )
                                                }
                                                return null
                                            })}
                                        </ul>
                                    ) : (
                                        <div className='no-results'>
                                            <p className='font-normal text-flint text-sm'>
                                                No results found
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='flyout-section flyout-section-outer pages-section mt-1 mb-4'>
                                <div className='flyout-section-inner'>
                                    <h3 className='flyout-section-title text-base xl:text-lg mb-2'>
                                        Pages + Posts
                                    </h3>
                                    {pages && pages.length > 0 ? (
                                        <ul className='pages-list nostyle flex flex-wrap gap-2 px-2 xl:flex xl:flex-col xl:flex-nowrap'>
                                            {pages.map((page) => {
                                                return (
                                                    <li
                                                        key={page.id}
                                                        className='text-sm font-normal'
                                                    >
                                                        <a
                                                            className='font-normal text-flint hover:text-ash hover:underline cursor-pointer'
                                                            href={page.url}
                                                        >
                                                            <Highlight
                                                                search={query}
                                                            >
                                                                {page.title}
                                                            </Highlight>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    ) : (
                                        <div className='no-results'>
                                            <p className='font-normal text-flint text-sm'>
                                                No results found
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchFlyout
